// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins/forms';

.rc-select {
  // display: inline-block;
  // font-size: 12px;
  position: relative;
  .rc-select-selection-placeholder {
    opacity: 0.4;
    pointer-events: none;
    position: absolute;
    top: 0.375rem;
    line-height: $input-line-height;
  }
  .rc-select-selection-search-input {
    appearance: none;
    &::-webkit-search-cancel-button {
      display: none;
      appearance: none;
    }
  }
  &.rc-select-focused:not(.rc-select-customize-input) {
    .rc-select-selector {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      border-color: #86b7fe;
    }
  }
}
.rc-select-disabled,
.rc-select-disabled input {
  cursor: not-allowed;
}
.rc-select-disabled {
  .rc-select-selector {
    opacity: 0.9;
  }
}
.rc-select-show-arrow {
  &.rc-select-loading {
    .rc-select-arrow-icon {
      &::after {
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        border: 2px solid #999;
        border-top-color: transparent;
        border-bottom-color: transparent;
        transform: none;
        margin-top: 4px;
        animation: rcSelectLoadingIcon 0.5s infinite;
      }
    }
  }
  &.rc-select-multiple {
    .rc-select-selector {
      padding-right: 20px;
    }
  }
  .rc-select-arrow {
    pointer-events: none;
    position: absolute;
    // top: $input-padding-y;
    // right: $input-padding-x;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .rc-select-arrow-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    width: 20px;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='448' height='512' fill='rgb(170, 170, 170)' viewBox='0 0 448 512'><path d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/></svg>");
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center right;
    /*
    &::after {
      content: '\25bc';
      color: #aaa;
      line-height: $input-line-height;
      // zoom: 0.8;
      // content: '';
      // border: 5px solid transparent;
      // width: 0;
      // height: 0;
      // display: inline-block;
      // border-top-color: #999;
      // transform: translateY(5px);
    }
    */
  }
}
.rc-select-single {
  .rc-select-selector {
    display: flex;
    position: relative;
    .rc-select-selection-search {
      width: 100%;
    }
    .rc-select-selection-search-input {
      width: 100%;
    }
  }
  &:not(.rc-select-customize-input) {
    .rc-select-selector {
      @extend .form-control;
      padding: 0;
      // padding: 1px;
      // border: 1px solid #000;
      .rc-select-selection-search-input {
        @extend .form-control;
        border: 0;
        // border: none;
        // outline: none;
        // background: rgba(255,0,0,0.2);
        // width: 100%;
      }
    }
  }
}
.rc-select-single .rc-select-selector .rc-select-selection-item,
.rc-select-single .rc-select-selector .rc-select-selection-placeholder {
  position: absolute;
  pointer-events: none;
  // top: 1px;
  // left: 3px;
  top: 0;
  left: 0;
  right: $input-padding-x * 2.4;
  padding: $input-padding-y $input-padding-x;
  // display: none;
  white-space: nowrap;
  overflow: hidden;
}
.rc-select-multiple {
  .rc-select-selector {
    display: flex;
    flex-wrap: wrap;
    padding: 1px;
    border: 1px solid #000;
    .rc-select-selection-item {
      flex: none;
      background: #bbb;
      border-radius: 4px;
      margin-right: 2px;
      padding: 0 8px;
    }
    .rc-select-selection-item-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .rc-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .rc-select-selection-overflow-item {
      flex: none;
      max-width: 100%;
    }
    .rc-select-selection-search {
      position: relative;
      max-width: 100%;
    }
    .rc-select-selection-search-mirror {
      position: absolute;
      z-index: 999;
      white-space: nowrap;
      position: none;
      left: 0;
      top: 0;
      visibility: hidden;
    }
    .rc-select-selection-search-input {
      border: none;
      outline: none;
      background: rgba(255,0,0,0.2);
      width: 100%;
    }
  }
  &:not(.rc-select-customize-input) {
    .rc-select-selector {
      @extend .form-control;
    }
  }
}
.rc-select-multiple .rc-select-selector .rc-select-selection-search-input,
.rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
  padding: 1px;
  font-family: system-ui;
}
.rc-select-allow-clear {
  &.rc-select-multiple {
    .rc-select-selector {
      padding-right: 20px;
    }
  }
  .rc-select-clear {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    width: 40px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' fill='rgb(170, 170, 170)' viewBox='0 0 512 512'><path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/></svg>");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center right;
    .rc-select-clear-icon {
      display: none;
    }
    // &:after {
    //   content: '\f057';
    //   font-family: ???;
    // }
  }
}
.rc-select-focused {
  .rc-select-selector {
    // border-color: blue !important;
    border-color: $input-focus-border-color !important;
  }
}
.rc-select-dropdown {
  // border: 1px solid green;
  border: $input-border-width solid $input-border-color;
  min-height: 100px;
  position: absolute;
  z-index: 1055;
  background: #fff;
}
.rc-select-dropdown-hidden {
  display: none;
}
.rc-select-item {
  font-size: 16px;
  line-height: 1.5;
  padding: 4px 16px;
}
.rc-select-item-group {
  color: #999;
  font-weight: bold;
  font-size: 80%;
}
.rc-select-item-option {
  position: relative;
  .rc-select-item-option-state {
    position: absolute;
    right: 0;
    top: 4px;
    pointer-events: none;
  }
}
.rc-select-item-option-grouped {
  padding-left: 24px;
}
.rc-select-item-option-active {
  // background: green;
  background: $input-focus-border-color;
  cursor: pointer;
}
.rc-select-item-option-disabled {
  color: #999;
}
.rc-select-item-empty {
  text-align: center;
  color: #999;
}
.rc-select-allow-clear .rc-select-clear {
  color: #999;
  font-size: 1.6rem;
  right: 2rem;
  padding: 0 0.4rem;
}
.rc-select-selection__choice-zoom {
  transition: all 0.3s;
}
.rc-select-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);
  &.rc-select-selection__choice-zoom-appear-active {
    opacity: 1;
    transform: scale(1);
  }
}
.rc-select-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);
  &.rc-select-selection__choice-zoom-leave-active {
    opacity: 0;
    transform: scale(0.5);
  }
}
.rc-select-dropdown-slide-up-enter,
.rc-select-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08,0.82,0.17,1);
  animation-play-state: paused;
}
.rc-select-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6,0.04,0.98,0.34);
  animation-play-state: paused;
  &.rc-select-dropdown-slide-up-leave-active {
    &.rc-select-dropdown-placement-bottomLeft {
      animation-name: rcSelectDropdownSlideUpOut;
      animation-play-state: running;
    }
    &.rc-select-dropdown-placement-topLeft {
      animation-name: rcSelectDropdownSlideDownOut;
      animation-play-state: running;
    }
  }
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpIn;
  animation-play-state: running;
}
.rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
.rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownIn;
  animation-play-state: running;
}

@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@keyframes rcSelectLoadingIcon {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

body.theme-dark {
  .rc-select {
    &.rc-select-focused:not(.rc-select-customize-input) {
      .rc-select-selector {
        box-shadow: 0 0 0 0.25rem rgba(34, 139, 34, 0.3);
        background-color: #111;
        border-color: #000;
      }
    }
  }
  .rc-select-focused {
    .rc-select-selector {
      border-color: #000 !important;
    }
  }
  .rc-select-multiple {
    .rc-select-selector {
      .rc-select-selection-item {
        background: #222;
      }
    }
  }
  .rc-select-dropdown {
    margin-top: 0.5rem;
    border-color: #000;
    background: #111;
    box-shadow: 0 0 0 0.25rem rgba(34, 139, 34, 0.3);
    border-radius: 0.25rem;
  }
  .rc-select-show-arrow {
    .rc-select-arrow-icon {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='448' height='512' fill='rgba(34, 139, 34, 0.3)' viewBox='0 0 448 512'><path d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/></svg>");
    }
  }
  .rc-select-allow-clear {
    .rc-select-clear {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' fill='rgba(34, 139, 34, 0.3)' viewBox='0 0 512 512'><path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/></svg>");
    }
  }
  .rc-select-item-option-active {
    background: rgba(34, 139, 34, 0.3);
  }
}
