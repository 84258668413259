.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  > :global(.container-fluid) {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    > :global(.row) {
      width: calc(100% + var(--bs-gutter-x));
    }
  }
  main {
    overflow: auto;
    height: 100%;
  }
}

.Divider {
  flex-shrink: 0;
  width: 1.5rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1), inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.Loader {
  text-align: center;
  font-size: 2.4em;
  opacity: .2;
  margin: 1em 0;
}

.Sidebar {
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  width: 16rem;
  flex-basis: 16rem;
}

@media print {
  .App,
  .App > :global(.container-fluid),
  main {
    display: block;
    height: auto;
  }
  .Sidebar,
  .Divider {
    display: none;
  }
}
