$enable-negative-margins: true;

// Ensure Font Awesome styles are included in build
// @import '~@fortawesome/fontawesome-svg-core/styles';
// Copied to local file and modified to prevent issue with calc(var(...))
@import 'src/styles/font-awesome';

// Styles used by react-virtualized-auto-sizer to track when an element's size changes
@import 'src/styles/detectElementResize';

// Include only variables and mixins to prevent unused CSS
@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import 'src/styles/bootstrap-forms';

// Include mixins and helper classes for screen readers
// The visually-hidden and visually-hidden-focusable classes will be available globally
@import '~bootstrap/scss/mixins/_visually-hidden';
@import '~bootstrap/scss/helpers/_visually-hidden';

// Import button styles
@import '~bootstrap/scss/mixins/buttons';
@import '~bootstrap/scss/buttons';

// Import card styles
@import '~bootstrap/scss/card';
.card {
  box-shadow: 0 0 0 2px rgba(62, 86, 116, 0.4);
  .card-header {
    font-weight: bold;
    background: rgba(62, 86, 116, 0.2);
    margin: -1px;
    position: relative;
    z-index: 1;
  }
}

// Import table styles
@import '~bootstrap/scss/tables';

// Import button group styles
@import '~bootstrap/scss/button-group';

// Import list group styles
@import '~bootstrap/scss/mixins/list-group';
@import '~bootstrap/scss/list-group';

// Import breadcrumb styles
@import '~bootstrap/scss/breadcrumb';

// Import progress styles
@import '~bootstrap/scss/progress';

// Import badge styles
@import '~bootstrap/scss/badge';

// Import modals
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/close';

// Import utility styles
@import '~bootstrap/scss/utilities';

// Import styles for rc-select. These are based on node_modules/rc-select/assets/index.less.
@import 'src/styles/rc-select';

:root {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.svg-inline--fa, .svg-inline--fa * {
  box-sizing: content-box;
}

html {
  line-height: 1.15; // Correct the line height in all browsers.
  -webkit-text-size-adjust: 100%; // 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
  -webkit-tap-highlight-color: rgba($black, 0); // Change the default tap highlight to be completely transparent in iOS.
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.theme-dark {
  $purple: #9e00fa;
  background: #111;
  // color: forestgreen;
  color: white;
  .text-body {
    color: white !important;
  }
  dialog {
    color: white;
  }
  font-weight: 600;
  --bs-secondary-rgb: 0, 0, 0;
  a {
    color: #9e00fa;
  }
  .bg-primary {
    background: $purple !important;
  }
  .btn-primary {
    background: $purple;
    &:focus {
      box-shadow: 0 0 0 .25rem transparentize($purple, .6);
    }
    border: 1px solid rgba(0, 0, 0, .3);
    color: white;
  }
  .btn-dark {
    color: black;
    background: white;
    border-color: white;
    &:focus {
      box-shadow: 0 0 0 .25rem transparentize(white, .8);
    }
  }
  .btn-outline-dark {
    border-color: white;
    color: inherit;
    &:focus {
      box-shadow: 0 0 0 .25rem transparentize(white, .8);
    }
  }
  .btn-outline-primary {
    border-color: $purple;
    color: $purple;
    &:hover {
      background: $purple;
      color: white;
    }
  }
  .btn-link {
    color: $purple;
    &:focus {
      box-shadow: 0 0 0 .25rem transparentize($purple, .6);
    }
  }
  .card {
    background-color: #222;
    box-shadow: 0 0 0 2px transparentize($purple, .4);
    .card-header {
      background: transparentize($purple, .8);
    }
  }
  .table {
    $table-text-color: white;
    color: $table-text-color;
    --bs-table-active-color: $table-text-color;
    --bs-table-hover-color: $table-text-color;
    --bs-table-striped-color: $table-text-color;
    --bs-table-striped-bg: rgba(0, 0, 0, .3);
    --bs-table-hover-bg: rgba(0, 0, 0, .5);
  }
  .table-info {
    $table-text-color: white;
    color: $table-text-color;
    --bs--color: $table-text-color;
    --bs-table-active-color: $table-text-color;
    --bs-table-hover-color: $table-text-color;
    --bs-table-striped-color: $table-text-color;
    --bs-table-bg: #3e4344;
    --bs-table-striped-bg: #2e3132;
    --bs-table-hover-bg: rgba(0, 0, 0, .12);
  }
  .input-group-text {
    background: #333;
    border-color: #111;
    color: inherit;
  }
  .list-group-item {
    background: transparent;
    color: inherit;
  }
  .progress {
    background: #222;
  }
}

// @import '~bootstrap/scss/vendor/rfs';
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins/deprecate';
// @import '~bootstrap/scss/mixins/breakpoints';
// @import '~bootstrap/scss/mixins/grid';
// @import '~bootstrap/scss/mixins/lists';
// @import '~bootstrap/scss/mixins/text-truncate';

small, mark {
  display: inline;
}
@import '~bootstrap/scss/type';

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'Zilla Slab', 'Noto Sans JP', serif;
  font-weight: 400;
}
a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

.w-fit {
  width: 1px !important;
  white-space: nowrap !important; 
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
