.Pods {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  :global(.modal) & {
    grid-template-columns: none;
  }
  gap: 10px;
  // grid-auto-columns: 300px;
  ul {
    padding: 0 0 0 1em;
  }
  li {
    list-style: none;
  }
}
