.Filters {
  position: sticky;
  top: 0;
  background: white;
  &:before {
    content: '';
    position: absolute;
    top: -1rem;
    left: 0;
    right: 0;
    height: 1rem;
    background: white;
  }
  > * {
    position: relative;
  }
}
body:global(.theme-dark) {
  .Filters,
  .Filters:before,
  .Table > table > thead,
  .Table > table > tfoot,
  .Table > table > tfoot:after {
    background: #111;
  }
  .Table > :not(caption) > * > * {
    border-bottom-color: black;
  }
}

.Table {
  position: relative;
  > table {
    margin-bottom: 0;
    > thead {
      position: sticky;
      top: 0.5rem;
      background: white;
      th {
        box-shadow: 0 -1px 0 currentColor inset;
      }
    }
    > tbody {
      a {
        display: block;
        margin: -0.5rem;
        padding: 0.5rem;
        text-decoration: none;
        color: inherit;
      }
      tr:hover a {
        text-decoration: underline;
        &:global(.link) {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    > tfoot {
      position: sticky;
      bottom: 0;
      background: white;
      td {
        box-shadow: 0 1px 0 currentColor inset;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -1rem;
        left: 0;
        right: 0;
        height: calc(1rem + 1px);
        background: white;
      }
    }
  }
}

.Table.hasFiltersRow > table > thead {
  top: 2.9rem;
}
