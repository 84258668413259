// Styles used by react-virtualized-auto-sizer to track when an element's size
// changes. The styles are included in the module itself, but the method used to
// inject them causes a CSP violation. To work around this, the styles are added
// to the stylesheet here, and a DIV#detectElementResize element is created to
// prevent the module from attempting to include the CSS itself.
// See https://github.com/bvaughn/react-virtualized-auto-sizer/blob/master/src/vendor/detectElementResize.js

@keyframes resizeanim {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

.resize-triggers {
  animation: 1ms resizeanim;
  visibility: hidden;
  opacity: 0;
}

.resize-triggers,
.resize-triggers > div,
.contract-trigger:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

.resize-triggers > div {
  background: #eee;
  overflow: auto;
}

.contract-trigger:before {
  width: 200%;
  height: 200%;
}
