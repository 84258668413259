// Import navbar styles
@import '~bootstrap/scss/bootstrap-utilities';
$nav-link-color: #080941;
$nav-link-hover-color: lighten(#3e5674, 20%);
$nav-pills-link-active-bg: #080941;
// $nav-pills-link-active-bg: rgb(158, 0, 250);
// $nav-pills-link-active-color: rgb(232, 241, 104);
@import '~bootstrap/scss/navbar';
.sticky-top {
  position: sticky;
  top: 0;
}

// Import nav menu styles
@import '~bootstrap/scss/nav';

// Import styles needed for navbar-collapse
@import '~bootstrap/scss/transitions';

body.theme-dark {
  .nav-link {
    color: rgb(158, 0, 250);
    &:hover {
      color: darken(rgb(158, 0, 250), 16%);
    }
    &.active {
      background: darken(rgb(158, 0, 250), 40%);
      color: rgb(158, 0, 250);
    }
  }
}
