.Nav {
  z-index: 1020;
  // background: #3e5674;
  // background: linear-gradient(135deg, #3e5674, #2c4461);
  background: #080941;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .3);
  // svg { color: rgb(228, 233, 110); }
  :global(.nav-link) {
    color: white;
  }
  // Hide the hamburger menu icon on print
  :global(.navbar-toggler) {
    @media print {
      display: none;
    }
  }
}

.ThrasioLogo {
  $width: 9.2rem;
  $height: 2rem;
  display: block;
  margin: -0.2rem 0 -0.6rem -0.4rem;
  vertical-align: middle;
  width: $width;
  height: $height;
  background-size: $width $height;
  background-color: #55fff5;
  mask: url(./thrasio.svg) no-repeat center;
  mask-size: $width $height;
  + span {
    display: block;
    font-size: 0.8rem;
    text-align: right;
    margin-right: 0.4rem;
    margin-bottom: -0.2rem;
  }
}
