// Form styles
/*
Dark colors:
$input-border-color: #111;
$input-color: forestgreen;
$input-bg: #222;
$input-focus-border-color: #000;
$input-focus-color: forestgreen;
$input-focus-bg: #111;
*/

@import '~bootstrap/scss/vendor/rfs';
@import '~bootstrap/scss/mixins/border-radius';
@import '~bootstrap/scss/mixins/box-shadow';
@import '~bootstrap/scss/mixins/transition';
@import '~bootstrap/scss/mixins/gradients';
@import '~bootstrap/scss/mixins/forms';
@import '~bootstrap/scss/forms';
$custom-checkbox-indicator-border-radius: 3px;
$custom-control-indicator-border-color: #313131;
$custom-control-indicator-bg: #313131;
$custom-control-indicator-checked-border-color: #313131;
$custom-control-indicator-checked-bg: #4b4b4b;
$custom-control-indicator-focus-box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.25);
$custom-control-indicator-focus-border-color: #222;
$custom-control-indicator-active-border-color: #313131;
$custom-control-indicator-active-bg: #313131;
$custom-control-indicator-disabled-bg: #292928;
$custom-control-indicator-checked-disabled-bg: #292928;

.form-control {
  box-sizing: border-box;
  body.theme-dark & {
    border-color: #111;
    color: forestgreen;
    background: #222;
    &::placeholder {
      color: rgba(34, 139, 34, 0.4);
    }
    &:focus {
      border-color: #000;
      color: forestgreen;
      background: #111;
      box-shadow: 0 0 0 0.25rem rgba(34, 139, 34, 0.3);
    }
  }
}

.form-actions {
  margin-top: 1.6rem;
  text-align: right;
}

.select-with-placeholder {
  &.placeholder-active,
  > option:first-child {
    color: $input-placeholder-color;
  }
}

.form-check-input {
  body.theme-dark & {
    border-color: #111;
    color: forestgreen;
    background: #222;
  }
}

// @import 'node_modules/bootstrap/scss/custom-forms';

.custom-control-input {
  &:checked ~ .custom-control-label::before {
    border-color: #f9f9fa;
    background-color: #dedee3;
  }
  &:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(142, 142, 215, 0.1);
  }
  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #eee;
  }
  &:not(:disabled):active ~ .custom-control-label::before {
    background-color: #f9f9fa;
    border-color: #f9f9fa;
  }
  &:disabled ~ .custom-control-label::before,
  &:disabled:checked ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
}

.custom-control-label {
  &:before {
    background-color: #f9f9fa;
    border-color: #f9f9fa;
  }
}

.form-check-label {
  line-height: 1.5;
}

// Clear button styles for search inputs
input[type="search"] {
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
  &:focus::-webkit-search-cancel-button {
    pointer-events: all;
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    width: 40px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='512' height='512' fill='rgb(170, 170, 170)' viewBox='0 0 512 512'><path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/></svg>");
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center right;
  }
}
