.Spinner {
  display: inline-block;
  overflow: hidden;
  text-align: right;
  vertical-align: middle;
  margin: -2px 0;
  transition: all 0.2s ease;
  width: 0;
  &.Waiting {
    width: 1em;
    margin: -0.4em 0 -0.4em 0.6em;
  }
}
